// COLORS
$primary-color: lightblue;
$secondary-color: navy;

$white-color: rgb(255, 255, 255);

$dark-grey: hsl(0, 3%, 85%);
$light-grey: #d2d2d2;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.8rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
